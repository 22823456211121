<template>
  <div class="config-view h-100 p-20">
    <h3 class="m-b-10">查看详情</h3>
    <Button type="error" size="small" @click="$router.back()" class="extra"
      >返回</Button
    >
    <Row :gutter="10">
      <Col span="12">
        <Card title="基础信息" dis-hover>
          <Vgroup :label-width="120">
            <Vcell label="活动名称：" :value="view.activityName" />
            <Vcell label="参与人员：">
              <Tag
                v-for="(item, index) in view.activityParticipants"
                :key="index"
                >{{ item.userName }}</Tag
              >
            </Vcell>
            <Vcell label="活动开始时间：" :value="view.startTime" />
            <Vcell label="活动结束时间：" :value="view.endTime" />
            <Vcell
              label="活动发起人："
              :value="view.owner && view.owner.userName"
            />
            <Vcell
              label="所属组织："
              :value="view.organization && view.organization.orgName"
            />
            <Vcell label="研学活动路线：" v-if="view.researchLines">
              <span v-for="(item, index) in researchLineName" :key="index">{{
                item
              }}</span
              ><span v-if="researchLineName.length > 1">、</span>
            </Vcell>
            <Vcell label="研学活动点：" v-if="view.researchPoints">
              <span v-for="(item, index) in polylinePath" :key="index">
                {{ item.pointName }}
                <span
                  v-if="
                    polylinePath.length > 1 && index < polylinePath.length - 1
                  "
                  >、</span
                >
              </span>
            </Vcell>
            <Vcell label="活动详情：">
              <div v-html="view.activityDetail"></div>
            </Vcell>
          </Vgroup>
        </Card>
      </Col>
      <Col span="12">
        <Card title="研学信息" dis-hover>
          <div class="map-warp" ref="map"></div>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import MapBase from "@/libs/graph/MapBase";
import { getDataById } from "@/libs/api/activity";
import { getLineById } from "@/libs/api/trace";
import { getPointById } from "@/libs/api/point";
import { downloadFile } from "@/libs/api/base";
import { util } from "@/libs";
import Vgroup from "../../../../components/libs/Vgroup.vue";
export default {
  components: { Vgroup },
  data() {
    return {
      map: null,
      tool: null,
      view: {},
      polylinePath: [],
      researchLineName: [],
    };
  },
  methods: {
    initMap() {},
    // 显示路线
    setViewLine(coords) {
      if (coords.length) {
        const { tool } = this;
        let linePoints = [],
          marker = null;
        const pts = coords.map((item, index) => {
          let pt = new T.LngLat(item.lon || item.lng, item.lat);
          Object.assign(pt, item);
          if (index == 0) {
            marker = tool.marker(pt, tool.icon("start_icon", 20));
          } else if (index == coords.length - 1) {
            marker = tool.marker(pt, tool.icon("end_icon", 20));
          }
          // else {
          //   marker = tool.marker(pt, tool.icon("position", 20));
          // }
          linePoints.push(marker);
          return pt;
        });
        this.polyline = this.tool.polyline(pts, {
          color: "#1e88e5",
          weight: 3,
          opacity: 1,
        });
        this.map.setViewport(pts);
      }
    },
    // 显示点
    setViewMarker(lng, lat, name, type = 0) {
      let nameByType = { 0: "研学点", 1: "研学路线" };
      let position = new T.LngLat(lng, lat);
      let marker = new T.Marker(position);
      this.map.addOverLay(marker);
      this.map.centerAndZoom(position, 13);
      let infoWindow = new T.InfoWindow(`${nameByType[type]}名称：${name}`, {
        autoPan: true,
      });
      marker.addEventListener("click", () => {
        marker.openInfoWindow(infoWindow);
      });
    },

    // 解析gpx文件
    getLineGpx(lineGpx) {
      const fileId = lineGpx;
      downloadFile({ fileId }).then((res) => {
        let coords = util.getGPXNode(res);
        this.setViewLine(coords);
      });
    },
    // 获取研学路线详情
    getLine(lineId) {
      getLineById({ id: lineId }).then((res) => {
        if (res.code == "HA0200") {
          this.researchLineName.push(res.data.lineName);
          if (res.data.lineGpx) {
            this.getLineGpx(res.data.lineGpx);
          }
          this.setViewMarker(
            res.data.lineCenter.lon,
            res.data.lineCenter.lat,
            res.data.lineName,
            1
          );
        }
      });
    },

    // 获取研学点详情 
    getPoints(pointId) {
      getPointById({ id: pointId }).then(({ code, data }) => {
        this.setViewMarker(
          data.pointCoord.lon,
          data.pointCoord.lat,
          data.pointName
        );
        this.polylinePath.unshift({
          lng: data.pointCoord.lon,
          lat: data.pointCoord.lat,
          pointName: data.pointName,
        });
        // if(this.polylinePath.length == this.view.researchPoints.length) {
        //   // TODO: 显示研学线所含研学点的数据组成一条路线
        //   this.setViewLine(this.polylinePath);
        // }
      });
    },
  },
  async mounted() {
    await util.initTmap().then(() => {

      const map = new T.Map(this.$refs.map, {});
      this.tool = new MapBase(map);
      map.centerAndZoom(new T.LngLat(113.619320,34.747790), 11);
      map.enableScrollWheelZoom();
      this.map = map;
    })
    const { id } = this.$route.query;
    if (id) {
      const { code, data, msg } = await getDataById({ activity_id: id });
      if (code == "HA0200") {
        this.view = data;
        if (data.researchLines) {
          data.researchLines.map((item) => this.getLine(item));
        }
        if (data.researchPoints) {
          data.researchPoints.map((item) => this.getPoints(item));
        }
      } else {
        this.$Message.error(msg)
      }
    }
  },
};
</script>

<style lang="less" scoped>
.config-view {
  position: relative;
  .extra {
    position: absolute;
    top: 15px;
    right: 30px;
  }
}
.map-warp {
  width: 100%;
  height: 600px;
}
</style>
